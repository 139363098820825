// Initialize asset file download on successful Formie form submission
// https://verbb.io/craft-plugins/formie/docs/developers/javascript-api

(function (window) {
  function initFormieDownload() {
    // Get an already-initialised form by DOM node
    const $form = document.querySelector('[data-form="downloadForm"]');
    if ($form != null) {
      // Initialize asset download after successful form submission
      $form.addEventListener('onAfterFormieSubmit', (e) => {
        e.preventDefault();

        // Hide download file information on parent div
        const $formContainer = $form.closest('[data-form-container]');
        if ($formContainer) {
          $formContainer
            .querySelectorAll('[data-form-hide-on-submit]')
            .forEach((n) => n.remove());
        }

        // Show success message
        const $messageTemplate = $formContainer.querySelector(
          '[data-form-message]',
        );
        if ($messageTemplate) {
          const $clone = document.importNode($messageTemplate.content, true);
          $form.parentElement.insertBefore($clone, $form.nextSibling);
        }

        // Check if download url is available
        if (e.detail.assetUrl !== undefined && e.detail.assetUrl != '') {
          const $link = document.createElement('a');
          $link.href = e.detail.assetUrl;
          $link.target = '_blank';
          $link.dispatchEvent(new MouseEvent('click'));
        }
      });
    }
  }

  document.addEventListener('pjax:success', initFormieDownload);
  initFormieDownload();
})(this);
